import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faGithub, faLinkedin, faCodepen } from '@fortawesome/free-brands-svg-icons';
import { faUser, faEnvelope, faFileArrowDown, faBars, faXmark, faSimCard} from '@fortawesome/free-solid-svg-icons';


library.add(fab, faUser, faEnvelope, faFileArrowDown, faGithub, faLinkedin, faBars, faXmark, faCodepen, faSimCard)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
