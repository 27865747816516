import './intro.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PFP from '../../assets/PFP.png';
// import PFP from '../../assets/ARClogo.png';



function Intro() {
  return (
    <div className='intro' id='intro'>
      {/* front of card */}
      {/* <div className='cardradius'>
        <div className='cardborder'>
        </div>
      </div>
      <div className='card'>
        <div className='cardcontent'>
          <div className='pfp'>
            <img src={PFP} alt=""/>
          </div>
        </div>
        <FontAwesomeIcon icon="fa-solid fa-sim-card" className='simicon'/>
      </div> */}
      {/* back of card */}
      <div className='cardradius'>
        <div className='cardborder'>
        </div>

      </div>
      <div className='card'>
        <div className='cardcontent'>
              <div className='pfp'>
                <img src={PFP} alt=""/>
              </div>
              <div className=''>
                <p>I am a <span>Design Engineer</span></p>
              </div>
              <div className='buttons'>
                <div className='button' href="">
                  <p>WEB DEVELOPMENT</p>
                </div>
                <div className='button'>
                  <p>DESIGN</p>
                </div>
                <div className='button'>
                  <p>BUSINESS ENGLISH</p>
                </div>
              </div>
              <div className='socialicons'>
                <FontAwesomeIcon icon="fa-brands fa-linkedin" href='https://www.linkedin.com/in/isaac-hodgkinson/' className='icon'/>
                <FontAwesomeIcon icon="fa-brands fa-github" href='https://github.com/ICH21' className='icon'/>
                <FontAwesomeIcon icon="fa-brands fa-codepen" href='https://codepen.io/ich21' className='icon'/>
              </div>
        </div>
        <FontAwesomeIcon icon="fa-solid fa-sim-card" className='simicon'/>
      </div>
    </div>
  )
}

export default Intro