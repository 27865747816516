import Contact from './components/contact/Contact';
import Intro from './components/intro/Intro';
// import Portfolio from './components/portfolio/Portfolio';
// import Testimonials from './components/testimonials/Testimonials';
import './app.scss';
import background from './assets/space.jpg';


function App() {
  return (
    <div className="app">
    <div className='sections' style={{ backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat",backgroundSize: "cover"}}>
    <Intro />
    {/* <Portfolio />
    <Testimonials /> */}
    <Contact />
    </div>
    </div>
  );
}

export default App;
