import './contact.scss'

function Contact() {
  return (
    <div className='contact' id='contact'>
      <h1>contact</h1>
    </div>
  )
}

export default Contact

